<template>
  <v-layout class="d-block">
    <v-row>
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t('locations.titles.generalInformation') }}</h3>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/info-circle.svg')" contain height="25px" max-height="25px" width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="ma-4">
                <div class="d-flex justify-space-between align-center align-content-center mx-2">
                    <h2>
                      {{ item.name }}
                    </h2>
                    <v-img v-if="item.logo && item.logo.url" contain :aspect-ratio="16/9" :src="item.logo.url" height="50" max-width="30%" />
                  </div>
              </v-col>
              <v-col cols="6">
                <div class="card__items">
                    <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.storage_type") }}:</h2>
                      {{ item.storage_device_type_id ? item.storage_device_type_id.storage_type_name : '-' }}
                    </div>
                    <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.gln") }}:</h2>
                      {{ item.gln }}
                    </div>
                    <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.area") }}:</h2>
                      {{ item.area }}
                    </div>
                  </div>
              </v-col>
              <v-col cols="12">
                <span style="display: block; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                  <h2 class="font-weight-bold">{{ $t("locations.fields.description") }}:</h2>
                  <div style="height: 100px; overflow: auto">
                    <read-more-component
                            :classes="'mb-0'"
                            :text="translate(item.description) ||'-'"
                            :max-chars="300"
                            :font-size="14"
                    />
                  </div>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <map-box
            :displayDrawControlsDefault="false"
            :drawControl="true"
            :drawControls="controls"
            :geolocateControl="true"
            :mapConfiguration="mapConfig"
            :navigationControl="false"
            :oneLocation="true"
            :scaleControl="true"
            :searchControl="false"
            ref="mapLocations"
            :fullScreenControl="true"
            v-on:areaChange="setDataFormLocations($event)"
            :confStyle="'satellite-v9'"
            :mapHeight="50"
            v-on:loadComplete="initializeMap"
            v-on:styleLoad="reload(true)"
            :actionStyle="false"
            :layers="layers"
          />
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-12" v-if="item.images && item.images.length > 0">
        <SlideShowComponent :images="item.images" property="url"></SlideShowComponent>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
 import MapBox from "@/components/vueMapbox/MapBox.vue";
 import SlideShowComponent from "@/components/common/SlideShowComponent.vue";
 import {mapboxMixin} from "@/mixins/mapbox.mixin";
 import {mapGetters} from "vuex";
 import LanguageService from "@/services/LanguajeService"
 import ReadMoreComponent from '@/components/ReadMoreComponent.vue';

 export default {
   name: "TabShowLocation",
   components: {MapBox, SlideShowComponent, ReadMoreComponent},

   props: {
    item: {
      type: Object,
      required: true
    },
   },

   data: () => ({
     controls: {
       polygon: false,
       point: false,
       trash: false,
     },
     ready: false,
     layers: [
       { 'id': 'l_locations_clusters', checked: false },
       { 'id': 'l_locations_clusters_count',checked: false },
       { 'id': 'l_locations_names', checked: false},
       { 'id': 'l_locations_up_zoom', checked: false },
       { 'id': 'l_locations_down_zoom', checked: false },
       { 'id': 'l_locations_center_points', checked: false },
       { 'id': 'l_locations_center_points_icon', checked: false },
       { 'id': 'l_locations_all', checked: true },
       { 'id': 'l_locations_fill_all', checked: true },
       { 'id': 'l_locations_clusters_points', checked: false },
       { 'id': 'l_locations_clusters_points_icon', checked: false },

       { 'id': 'l_storages_clusters', checked: false },
       { 'id': 'l_storages_clusters_count',checked: false },
       { 'id': 'l_storages_names', checked: false},
       { 'id': 'l_storages_up_zoom', checked: true },
       { 'id': 'l_storages_down_zoom', checked: true },
       { 'id': 'l_storages_center_points', checked: false },
       { 'id': 'l_storages_center_points_icon', checked: false },
       { 'id': 'l_storages_all', checked: true },
       { 'id': 'l_storages_clusters_points', checked: false },
       { 'id': 'l_storages_clusters_points_icon', checked: false }

     ],
   }),

   mixins: [mapboxMixin],

   computed: {
     ...mapGetters({
        storageDeviceTypes:"storage_type/storageDeviceTypesActiveByCompany",
     }),
   },

   methods: {
     async initializeMap() {
       await this.reload(true);
       this.ready = true
     },
     translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
   }
 }
</script>


<style scoped>
.fill-icon__color {
  filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(400%) contrast(88%);
}

:deep(.mapboxgl-map) {
  border-radius: 4px !important;
}

.card-hover {
  box-shadow: none;
  transition: all 200ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
}

.card__items {
  display: grid;
  grid-template-columns: 100% 100%;
  gap: 10px
}
</style>